import { useEffect, useRef, useState } from 'react';
import Configurator from '../config/Configurator.js';
import { useDispatch } from 'react-redux';
import { setLedLive } from '../services/slices/appConfig.js';
import { clearCache } from '../api/Fetchers/fetch.old.data.js';

const useWebSocket = (isLoggedIn, liveFlag, processLiveData, setIsLoggedIn, isSliding) => {
    const [ws, setWs] = useState(null);
    const dispatch = useDispatch();
    const terminate = useRef(false);

    useEffect(() => {
        let wsInstance = null;
        let reconnectTimeout = null;
        const initializeWebSocket = async () => {
            /*** * Data Definitions * ***/

            let wsUrl;
            let wsInstance;
            let config;
            let apiKey, userID, serial;

            /*** * Data Init * ***/

            config = await Configurator();

            apiKey = localStorage.getItem("apiKey");
            userID = localStorage.getItem("userID");
            serial = localStorage.getItem("serial");

            // wsUrl
            (()=>{
                const host = (new URL(config.API_URL)).host;
                const wsPrefix = config.API_WS_PREFIX;
    
                wsUrl = `${wsPrefix}://${host}/api/v2/db/redis/records_latest/get_stream`;                
            })();

            /*** * Action * ***/

            console.log("Initializing WebSocket...");

            if (!apiKey || !userID || apiKey.length === 0 || userID.length === 0) {
                console.log("Missing or invalid API key or user ID");
                return;
            }

            wsInstance = new WebSocket(wsUrl);
            setWs(wsInstance);

            /*** * Events * ***/

            wsInstance.onopen = function () {
                console.log("WebSocket connection opened");
                const firstMsg = JSON.stringify({
                    serial: serial,
                    intervalMs: 1000,
                    userId: parseInt(userID),
                    apiKey: apiKey
                });
                wsInstance.send(firstMsg);
            };

            wsInstance.onmessage = function (event) {
                console.log('WebSocket message received', isLoggedIn);
                if (!isLoggedIn) {
                    console.log('User is not logged in, closing WebSocket');
                    wsInstance.close(1000, "User logged out");
                    return;
                }
                if (liveFlag.current && !isSliding.current) {
                    const data = JSON.parse(event.data);

                    if (data?.Status?.ErrCode === 3.0) {
                        setIsLoggedIn(false);
                        localStorage.setItem("userID", '');
                        return;
                    } 
                    
                    if (data?.Status?.ErrCode > 0) {
                        return;
                    }

                    if (data?.Metadata?.repeat) {
                        dispatch(setLedLive(false));
                        return;
                    }

                    if (Object.keys(data?.Data).length > 0) {
                        processLiveData(data.Data);
                        dispatch(setLedLive(true));
                    } else {
                        dispatch(setLedLive(false));
                    }

                };
            }
            wsInstance.onclose = function () {
                console.log('WebSocket connection closed');
                if (isLoggedIn && !terminate.current) {
                    console.log('Re-initializing WebSocket');
                    clearTimeout(reconnectTimeout);
                    reconnectTimeout = setTimeout(initializeWebSocket, 1000); // Reconnect after 1 second
                } else {
                    setWs(null);
                }
            };

            wsInstance.onerror = function (error) {
                console.log('WebSocket error', error);
                if (isLoggedIn && !terminate.current) {
                    console.log('WebSocket error, re-initializing');
                    clearTimeout(reconnectTimeout);
                    reconnectTimeout = setTimeout(initializeWebSocket, 1000); // Reconnect after 1 second
                } else {
                    setWs(null);
                }
            };
        };

        if (isLoggedIn) {
            console.log('User is logged in, initializing WebSocket');
            initializeWebSocket();
        } else if (ws) {
            clearCache()
            console.log('trying to close')
            terminate.current = true;
            processLiveData({})
            ws.close(1000, "User logged out");
            ws.onclose = () => {
                setWs(null);
            };
        }

        return () => {
            console.log('returned')
            if (ws) {
            console.log('returned',ws)

                terminate.current = true;
                ws.close(1000, "User logged out");
                ws.onclose = () => {
                    setWs(null);
                };
            }
            clearTimeout(reconnectTimeout);
        };
    }, [isLoggedIn, liveFlag]);

    return { ws };
};

export default useWebSocket;
