import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchMeta } from '../api/Fetchers/fetch.meta';
import { fetchTags } from '../api/Fetchers/fetch.tags';
import { setAlarms,setOperationAlarms } from '../services/slices/amsSlice';
import { setSensorGroups } from '../services/slices/sensorGroupSlice';
import { setRenamedSensors } from '../services/slices/renamedSensorsSlice';
import { setTags } from '../services/slices/tagsSlice';
import { setSensorLimits } from '../services/slices/sensorLimitsSlice';
import { setLiveData } from '../services/slices/liveDataSlice';
import { setLedLive } from '../services/slices/appConfig';
import { setSensorMultiplier } from '../services/slices/sensorMultiplierSlicer';

const useAppData = (isLoggedIn) => {
  const dispatch = useDispatch();
  const multiSensors = useSelector((state) => state.sensorMultiplier);
  const currentMulti = useRef({});

  useEffect(() => {
    if (isLoggedIn) {
      currentMulti.current = multiSensors;
    }
  }, [isLoggedIn, multiSensors]);

  useEffect(() => {
    if (!isLoggedIn) return;

    const fetchData = async () => {
      try {
        const [
          groups,
          renamed,
          limits,
          multiplier,
          tags,
          operationAlarms
        ] = await Promise.all([
          fetchMeta('sensor_groups'),
          fetchMeta('renamed_sensors'),
          fetchMeta('limits'),
          fetchMeta('multiplier'),
          fetchTags(),
          fetchMeta('alarms')
        ]);

        const sensorsGroups = groups ? JSON.parse(groups) : {};
        dispatch(setSensorGroups(sensorsGroups));

        const sensorsRenamed = renamed ? JSON.parse(renamed) : {};
        dispatch(setRenamedSensors(sensorsRenamed));

        const sensorsConfig = limits ? JSON.parse((JSON.parse(limits))) : {};
        dispatch(setSensorLimits(sensorsConfig));

        const sensorsMultiplier = multiplier ? JSON.parse(JSON.parse(multiplier)) : {};
        dispatch(setSensorMultiplier(sensorsMultiplier));

        console.log(operationAlarms,'operationAlarms')
        const operationAlarmsData = operationAlarms ? JSON.parse(operationAlarms) : {};
        dispatch(setOperationAlarms(operationAlarmsData));

        dispatch(setTags(tags));
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        document.querySelector("#index-loading").style.display = "none";
      }
    };

    fetchData();
  }, [isLoggedIn, dispatch]);

  const processLiveData = (data) => {
    let tags = {
      all: [],
      grouped: [],
      notGrouped: []
    };

    tags.all = data;

    Object.keys(tags.all).forEach(key => {
      if (key.startsWith("GROUP")) {
        tags.grouped[key] = tags.all[key];
      } else {
        tags.notGrouped[key] = currentMulti.current[key] ? tags.all[key] * currentMulti.current[key] : tags.all[key];
      }
    });

    dispatch(setAlarms(tags.grouped));
    dispatch(setLiveData(tags.notGrouped));
  };

  return { processLiveData };
};

export default useAppData;
